export default {
  TWITTER_AT: '@Wizards',
  GIT_COMMIT: '09923ef73881f7f45643a4c55c0cb76040eb33f0',
  CI_JOB_ID: '7312045728',
  LOCATOR_VERSION: 'local_version',
  EVENT_RESERVATION_GRAPHQL_SERVICE: 'https://api.tabletop.wizards.com/silverbeak-griffin-service/graphql',
  GOOGLE_ANALYTICS_ID: 'UA-15020098-23',
  GOOGLE_TAG_MANAGER_ID: 'GTM-5QMQ67K',
  WOTC_ANALYTICS_URL: 'https://sel.api.bi.wizards.com/'

}
